<template>
    <div class="layout-dashboard">
        <div class="grid">
            <div class="col-12 md:col-12 xl:col-3">
                <div class="card overview-box blue">
                    <div class="card-header">
                        <h6>Revenue Status</h6>
                        <div class="header-buttons">
                            <a href="#"><i class="pi pi-arrow-up"></i></a>
                        </div>
                    </div>
                    <div class="overview-content">
                        <h3>87363</h3>
                        <div class="overview-graph">
                            <span style="height: 12px;"><span class="graph-tooltip">12</span></span>
                            <span style="height: 40px;"><span class="graph-tooltip">40</span></span>
                            <span style="height: 26px;"><span class="graph-tooltip">26</span></span>
                            <span style="height: 17px;"><span class="graph-tooltip">17</span></span>
                            <span style="height: 36px;"><span class="graph-tooltip">36</span></span>
                            <span style="height: 51px;"><span class="graph-tooltip">51</span></span>
                        </div>
                    </div>
                    <div class="overview-footer">
                        <span><i class="pi pi-arrow-up"></i> <span>55%</span> increase in today sales</span>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-3">
                <div class="card overview-box teal">
                    <div class="card-header">
                        <h6>Page View</h6>
                        <div class="header-buttons">
                            <a href="#"><i class="pi pi-arrow-up"></i></a>
                        </div>
                    </div>
                    <div class="overview-content">
                        <h3>5592</h3>
                        <div class="overview-graph">
                            <span style="height: 30px;"><span class="graph-tooltip">30</span></span>
                            <span style="height: 43px;"><span class="graph-tooltip">43</span></span>
                            <span style="height: 48px;"><span class="graph-tooltip">48</span></span>
                            <span style="height: 40px;"><span class="graph-tooltip">40</span></span>
                            <span style="height: 52px;"><span class="graph-tooltip">52</span></span>
                            <span style="height: 18px;"><span class="graph-tooltip">18</span></span>
                        </div>
                    </div>
                    <div class="overview-footer down">
                        <span><i class="pi pi-arrow-down"></i> <span>12%</span> decrease in today sales</span>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-3">
                <div class="card overview-box orange">
                    <div class="card-header">
                        <h6>Bounce Rate</h6>
                        <div class="header-buttons">
                            <a href="#"><i class="pi pi-arrow-up"></i></a>
                        </div>
                    </div>
                    <div class="overview-content">
                        <h3>55.95%</h3>
                        <div class="overview-graph">
                            <span style="height: 20px;"><span class="graph-tooltip">20</span></span>
                            <span style="height: 23px;"><span class="graph-tooltip">23</span></span>
                            <span style="height: 52px;"><span class="graph-tooltip">52</span></span>
                            <span style="height: 18px;"><span class="graph-tooltip">18</span></span>
                            <span style="height: 28px;"><span class="graph-tooltip">28</span></span>
                            <span style="height: 42px;"><span class="graph-tooltip">42</span></span>
                        </div>
                    </div>
                    <div class="overview-footer">
                        <span><i class="pi pi-arrow-up"></i> <span>6%</span> increase in today sales</span>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-3">
                <div class="card overview-box pink">
                    <div class="card-header">
                        <h6>Products Sale Rate</h6>
                        <div class="header-buttons">
                            <a href="#"><i class="pi pi-arrow-up"></i></a>
                        </div>
                    </div>
                    <div class="overview-content">
                        <h3>15.37%</h3>
                        <div class="overview-graph">
                            <span style="height: 17px;"><span class="graph-tooltip">20</span></span>
                            <span style="height: 39px;"><span class="graph-tooltip">23</span></span>
                            <span style="height: 22px;"><span class="graph-tooltip">52</span></span>
                            <span style="height: 34px;"><span class="graph-tooltip">18</span></span>
                            <span style="height: 28px;"><span class="graph-tooltip">28</span></span>
                            <span style="height: 52px;"><span class="graph-tooltip">42</span></span>
                        </div>
                    </div>
                    <div class="overview-footer down">
                        <span><i class="pi pi-arrow-down"></i> <span>1.1%</span> decrease in today sales</span>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-9">
                <div class="grid">
                    <div class="col-12">
                        <div class="card sale-graph">
                            <div class="card-header">
                                <h6>Top Products</h6>
                            </div>
                            <div id="order-tabs-container" class="order-tabs">
                                <div class="order-tab" :class="{'order-tab-active':activeOrders===0}"
                                    @click="changeDataset($event)"
                                    data-label="New Sales" data-index="0" data-stroke="#00acac" >
                                    <h6>Current Week</h6>
                                    <h3>$455.55</h3>
                                </div>
                                <div class="order-tab" :class="{'order-tab-active':activeOrders===1}"
                                    @click="changeDataset($event)"
                                    data-label="Previous Sales" data-index="1" data-stroke="#2f8ee5">
                                    <h6>Previous Week</h6>
                                    <h3>$244.40</h3>
                                </div>
                            </div>
                            <div class="overview-chart">
                                <Chart ref="orders" type="line" :data="ordersChart" :options="ordersOptions"></Chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-12 xl:col-3">
                <div class="grid">
                    <div class="col-12 md:col-6 xl:col-12">
                        <div class="card traffic">
                            <div class="card-header">
                                <h6>Traffic</h6>
                            </div>
                            <span>26 May - 27 May</span>
                            <div class="traffic-chart">
                                <Chart ref="traffic" type="doughnut" :data="trafficChart" :options="trafficOptions" style="position:relative; width: 50%"></Chart>
                            </div>
                            <div id="traffic-tabs-container" class="traffic-tabs">
                                <div class="traffic-tab" :class="{'traffic-tab-active':activeTraffic===0}"
                                    @click="changeTrafficset($event)"
                                    data-label="New Sales" data-index="0"  >
                                    <h6>48%</h6>
                                    <i class="pi pi-facebook"></i>
                                    <span>Facebook</span>
                                </div>
                                <div class="traffic-tab " :class="{'traffic-tab-active':activeTraffic===1}"
                                    @click="changeTrafficset($event)"
                                    data-label="Previous Sales" data-index="1">
                                    <h6>26%</h6>
                                    <i class="pi pi-twitter"></i>
                                    <span>Twitter</span>
                                </div>
                                <div class="traffic-tab " :class="{'traffic-tab-active':activeTraffic===2}"
                                    @click="changeTrafficset($event)"
                                    data-label="Previous Sales" data-index="2">
                                    <h6>17%</h6>
                                    <i class="pi pi-github"></i>
                                    <span>Github</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 xl:col-12">
                        <div class="card goals">
                            <div class="card-header">
                                <h6>Today's Goal</h6>
                                <span><span>183</span> / 255</span>
                            </div>
                            <span>22 June, 2020</span>
                            <div class="goal-graph">
                                <Chart ref="goal" type="pie" :data="goalChart" :options="goalOptions" :style="{'width': '50%'}"></Chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-4">
                <div class="card leaderboard">
                    <div class="card-header">
                        <h6>Leaderboard</h6>
                    </div>
                    <ul>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/leader-1.png" alt="poseidon-layout" />
                                <p>Peter Taylor</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$224.44</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/leader-2.png" alt="poseidon-layout" />
                                <p>Nicolina Lindholm</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$86.05</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/leader-3.png" alt="poseidon-layout" />
                                <p>Su Hua</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$59.44</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/leader-4.png" alt="poseidon-layout" />
                                <p>Maria Illescas</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$12.32</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/avatar-dengra.png" alt="poseidon-layout" />
                                <p>Ezequiel Dengra</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$12.32</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/avatar-conan.png" alt="poseidon-layout" />
                                <p>Conan Matusov</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$12.32</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                        <li>
                            <div class="person">
                                <img src="layout/images/dashboard/seller-1.png" alt="poseidon-layout" />
                                <p>Hector Mariano</p>
                            </div>
                            <div class="person-numbers">
                                <h6>$12.32</h6>
                                <i class="pi pi-arrow-up"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-4">
                <div class="card product-list">
                    <div class="card-header">
                        <h6>Product List</h6>
                    </div>
                    <ul>
                        <li>
                            <div class="product">
                                <img src="layout/images/dashboard/image-product-1.png" alt="poseidon-layout" />
                                <div class="product-info">
                                    <p>Nike Air X12</p>
                                    <span>Sold <span>250</span> times</span>
                                </div>

                            </div>
                            <i class="pi pi-arrow-up"></i>
                        </li>
                        <li>
                            <div class="product">
                                <img src="layout/images/dashboard/image-product-2.png" alt="poseidon-layout" />
                                <div class="product-info">
                                    <p>Nike Sports Watch</p>
                                    <span>Sold <span>189</span> times</span>
                                </div>

                            </div>
                            <i class="pi pi-arrow-up"></i>
                        </li>
                        <li>
                            <div class="product">
                                <img src="layout/images/dashboard/image-product-3.png" alt="poseidon-layout" />
                                <div class="product-info">
                                    <p>Heinz Yellow Mustard</p>
                                    <span>Sold <span>24</span> times</span>
                                </div>

                            </div>
                            <i class="pi pi-arrow-up"></i>
                        </li>
                        <li>
                            <div class="product">
                                <img src="layout/images/dashboard/image-product-4.png" alt="poseidon-layout" />
                                <div class="product-info">
                                    <p>Beats Headphone</p>
                                    <span>Sold <span>24</span> times</span>
                                </div>

                            </div>
                            <i class="pi pi-arrow-down down"></i>
                        </li>
                        <li>
                            <div class="product">
                                <img src="layout/images/dashboard/image-product-5.png" alt="poseidon-layout" />
                                <div class="product-info">
                                    <p>Black Racing Bike</p>
                                    <span>Sold <span>24</span> times</span>
                                </div>

                            </div>
                            <i class="pi pi-arrow-up"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-4">
                <div class="card sellers">
                    <div class="card-header">
                        <h6>Seller List</h6>
                    </div>
                    <ul>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/seller-1.png" alt="poseidon-layout" />
                                <p>Hector Mariano</p>
                            </div>
                            <span>Tier 1</span>
                            <Rating :modelValue="val2" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu1" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu1.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/seller-2.png" alt="poseidon-layout" />
                                <p>Maria Illescas</p>
                            </div>
                            <span>Tier 2</span>
                            <Rating :modelValue="val2" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu2" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu2.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/seller-3.png" alt="poseidon-layout" />
                                <p>Ngô Hải Giang</p>
                            </div>
                            <span>Tier 3</span>
                            <Rating v-model="val1" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu3" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu3.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/seller-4.png" alt="poseidon-layout" />
                                <p>Tamas Bunce</p>
                            </div>
                            <span>Tier 4</span>
                            <Rating v-model="val1" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu4" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu4.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/avatar-conan.png" alt="poseidon-layout" />
                                <p>Conan Matusov</p>
                            </div>
                            <span>Tier 4</span>
                            <Rating v-model="val1" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu5" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu5.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/leader-4.png" alt="poseidon-layout" />
                                <p>Maria Illescas</p>
                            </div>
                            <span>Tier 4</span>
                            <Rating v-model="val1" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu6" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu6.toggle($event)"></Button>
                        </li>
                        <li>
                            <div class="seller">
                                <img src="layout/images/dashboard/leader-3.png" alt="poseidon-layout" />
                                <p>Su Hua</p>
                            </div>
                            <span>Tier 4</span>
                            <Rating v-model="val1" :readonly="true" :stars="3"></Rating>
                            <Menu ref="menu7" :popup="true" :model="items"></Menu>
                            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="$refs.menu7.toggle($event)"></Button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 md:col-6 xl:col-4">
                <div class="card tasks">
                    <div class="card-header">
                        <h6>Tasks</h6>
                    </div>
                    <div>
                        <div class="task task-1">
                            <div class="task-info">
                                <div class="task-name"><span>12</span> Orders to fulfill</div>
                                <i class="pi pi-tag"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-2">
                            <div class="task-info">
                                <div class="task-name"><span>40+</span> Payments to withdraw</div>
                                <i class="pi pi-money-bill"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-3">
                            <div class="task-info">
                                <div class="task-name"><span>4</span> Reports to revise</div>
                                <i class="pi pi-file"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-4">
                            <div class="task-info">
                                <div class="task-name"><span>6</span> Questions to respond</div>
                                <i class="pi pi-comment"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-5">
                            <div class="task-info">
                                <div class="task-name"><span>2</span> Chargebacks to review</div>
                                <i class="pi pi-check-square"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-1">
                            <div class="task-info">
                                <div class="task-name"><span>12</span> Orders to fulfill</div>
                                <i class="pi pi-tag"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                        <div class="task task-2">
                            <div class="task-info">
                                <div class="task-name"><span>40+</span> Payments to withdraw</div>
                                <i class="pi pi-money-bill"></i>
                            </div>
                            <div class="task-progress">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 xl:col-8">
                <div class="card sales">
                    <div class="card-header">
                        <h4>Recent Sales</h4>
                        <Dropdown :options="orderWeek" v-model="selectedOrderWeek" optionLabel="name" @change="recentSales($event)"
                            class="dashbard-demo-dropdown" :style="{'min-width': '8rem'}"></Dropdown>
                    </div>

                    <p>Your sales activity over time.</p>

                    <DataTable :value="products" :paginator="true" :rows="5" responsiveLayout="scroll">
                        <Column field="id" header="ID" :sortable="true" style="min-width:7rem">
                            <template #body="slotProps">
                                <span class="p-column-title">Id</span>
                                {{slotProps.data.id}}
                            </template>
                        </Column>
                        <Column field="category" header="Category" :sortable="true" style="min-width:12rem">
                            <template #body="slotProps">
                                <span class="p-column-title">Category</span>
                                {{slotProps.data.category}}
                            </template>
                        </Column>
                        <Column field="price" header="Price" :sortable="true" style="min-width:12rem">
                            <template #body="slotProps">
                                <span class="p-column-title">Price</span>
                                {{ formatCurrency(slotProps.data.price) }}
                            </template>
                        </Column>
                        <Column field="inventoryStatus" header="Status" :sortable="true" style="min-width:12rem">
                            <template #body="slotProps">
                                <span :class="'product-badge status-' + slotProps.data.inventoryStatus.toLowerCase()">{{ slotProps.data.inventoryStatus }}</span>
                            </template>
                        </Column>
                        <Column bodyStyle="text-align: center" style="min-width:8rem">
                            <template #body> <Button type="button" icon="pi pi-search"></Button></template
                        ></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductService from '../service/ProductService';

export default {
    data() {
        return {
            ordersChart: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
                datasets: [
                    {
                        label: 'Revenue',
                        data: [31, 83, 69, 29, 62, 25, 59, 26, 46],
                        borderColor: [
                            '#00acac',
                        ],
                        backgroundColor: [
                            'rgba(40, 136, 76, .05)'
                        ],
                        borderWidth: 2,
                        fill: true,
                        borderDash: [3, 6],
                        tension: .4
                    },
                    {
                        label: 'Cost',
                        data: [67, 98, 27, 88, 38, 3, 22, 60, 56],
                        borderColor: [
                            '#2f8ee5',
                        ],
                        backgroundColor: [
                            'rgba(47, 142, 229, 0.05)',
                        ],
                        borderWidth: 2,
                        fill: true,
                        pointRadius: 3,
                        tension: .4
                    }
                ]
            },
            ordersOptions: null,
            activeOrders: 0,
            trafficChart: null,
            trafficOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                responsive: true
            },
            activeTraffic: 0,
            goalChart: {
                labels: [
                    'Complete',
                    'Not Complete',
                    'Extra Tasks',
                ],
                datasets: [{
                    data:  [183, 62, 10],
                    backgroundColor: [
                        '#ffffff',
                        'rgba(255,255,255,.2)',
                        'rgba(255,255,255,.5)',
                    ],
                    borderWidth: 0,
                }]
            },
            goalOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                responsive: true,
            },
            items: [
                {label: 'View Profile', icon: 'pi pi-user'},
                {label: 'Update Profile', icon: 'pi pi-refresh'},
                {label: 'Delete Profile', icon: 'pi pi-trash'},
            ],
            val1: 1,
            val2: 2,
            orderWeek: [
                {name: 'This Week', code: '0'},
                {name: 'Last Week', code: '1'}
            ],
            selectedOrderWeek: null,
            products: null,
            productsThisWeek: null,
            productsLastWeek: null,
        }
    },
    productService: null,
    created() {
        this.productService = new ProductService();
    },
    mounted() {
        this.productService.getProducts().then(data => this.products = data);
        this.productService.getProducts().then(data => this.productsThisWeek = data);
        this.productService.getProductsMixed().then(data => this.productsLastWeek = data);

        this.selectedOrderWeek = this.orderWeek[0];
        this.trafficChart = this.getTrafficChartData();
        this.refreshChart();

    },
    watch: {
        '$appState.isNewThemeLoaded'(isLoaded) {
            if (isLoaded) {
                this.refreshChart();
                this.$appState.isNewThemeLoaded = false;
            }
        }
    },
    methods: {
        refreshChart() {
            this.trafficChart = this.getTrafficChartData();

            if (this.$appState.layoutMode !== 'light'){
                this.applyDarkTheme();
            } else {
                this.applyLightTheme();
            }
        },
        getTrafficChartData() {
            return {
                labels: [
                    'Add View',
                    'Total View',
                ],
                datasets: [{
                    data:  [48, 52],
                    backgroundColor: [
                        getComputedStyle(document.body).getPropertyValue('--primary-dark-color') || '#2c84d8',
                        getComputedStyle(document.body).getPropertyValue('--content-alt-bg-color') || '#B1B9C9',
                    ],
                    borderWidth: 0,
                }]
            };
        },
        changeDataset(event) {
            const dataSet = [
                [31, 83, 69, 29, 62, 25, 59, 26, 46],
                [40, 29, 7, 73, 81, 69, 46, 21, 96],
            ];
            const dataSet2 = [
                [67, 98, 27, 88, 38, 3, 22, 60, 56],
                [74, 67, 11, 36, 100, 49, 34, 56, 45],
            ];

            this.activeOrders = parseInt(event.currentTarget.getAttribute('data-index'));

            this.ordersChart.datasets[0].data = dataSet[parseInt(event.currentTarget.getAttribute('data-index'))];
            this.ordersChart.datasets[1].data = dataSet2[parseInt(event.currentTarget.getAttribute('data-index'))];
            this.ordersChart.datasets[0].label = event.currentTarget.getAttribute('data-label');
            this.ordersChart.datasets[0].borderColor = event.currentTarget.getAttribute('data-stroke');
        },
        changeTrafficset(event){
            const traffidDataSet = [
                [48, 52],
                [26, 74],
                [12, 88],
            ];
            this.activeTraffic = parseInt(event.currentTarget.getAttribute('data-index'));

            this.trafficChart.datasets[0].data = traffidDataSet[parseInt(event.currentTarget.getAttribute('data-index'))];
        },
        recentSales(event) {
            if (event.value.code === '0') {
                this.products = this.productsThisWeek;
            } else {
                this.products = this.productsLastWeek;
            }
        },
        applyDarkTheme() {
            this.ordersOptions = {
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: '#ebedef'
                        }
                    }
                },
                responsive: true,
                hover: {
                    mode: 'index'
                },
                scales: {
                    y: {
                        ticks: {
                            color: '#ebedef'
                        },
                        grid: {
                            color:  'rgba(160, 167, 181, .3)',
                        }
                    },
                    x: {
                        ticks: {
                            color: '#ebedef'
                        },
                        grid: {
                            color:  'rgba(160, 167, 181, .3)',
                        }
                    },
                }
            };
        },
        applyLightTheme() {
            this.ordersOptions = {
                plugins: {
					legend: {
                        display: true,
						labels: {
							color: '#A0A7B5'
						}
					}
				},
                responsive: true,
                hover: {
                    mode: 'index'
                },
                scales: {
                    y: {
                        ticks: {
                            color: '#A0A7B5'
                        },
                        grid: {
                            color:  'rgba(160, 167, 181, .3)',
                        }
                    },
                    x: {
                        ticks: {
                            color: '#A0A7B5'
                        },
                        grid: {
                            color:  'rgba(160, 167, 181, .3)',
                        }
                    },
                }
            };
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },
    }
}
</script>